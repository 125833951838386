import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import LoaderCircle from '../../components/loadercircle/LoaderCircle';
import { getValue } from '@testing-library/user-event/dist/utils';

const JobForm = (props) => {

    const [chosenCompany, setChosenCompany] = useState(JSON
        .parse(localStorage.getItem('session'))
        .LoginData
        .WebCompany);
    const [afterHoursFlag, setAfterHoursFlag] = useState(JSON.parse(localStorage.getItem('session')).LoginData.AfterHoursFlag);
    
    const [updateHour,setUpdateHour] = useState(0);
    const [updateMinutes,setUpdateMinutes] = useState(0);


    const {register, onChange, handleSubmit, reset, watch, setValue, getValues, formState: {errors}} = useForm({
        defaultValues: {
            TSDATE: props.todaysDate,
            TSCOMP: chosenCompany,
            TSTIMES_AFTERHOURS: afterHoursFlag
        }
    });

    const comp = watch('TSCOMP');

   const updateAfterHoursFlag = () => {
       let session = JSON.parse(localStorage.getItem('session'));
       session.LoginData.AfterHoursFlag = !session.LoginData.AfterHoursFlag;
       localStorage.setItem('session', JSON.stringify(session));
       setAfterHoursFlag(session.LoginData.AfterHoursFlag);
       console.log('Update Triggered')

   }

   const changeTime = (time,type) => {
        if(props.edit) {
            let editTime = getValues('TSTIME');
            console.log(editTime);
            let splitTime = editTime.split(':');
            setUpdateHour(parseInt(splitTime[0]));
            setUpdateMinutes(parseInt(splitTime[1]));
        }
        if(type === 'hour') {
            if(time === 'add'){
               
                let hour = parseInt(updateHour) + 1;
                console.log(hour);
                setUpdateHour(hour);
                setValue('TSTIME', (hour <10 ? "0"+ hour: hour) +":"+(updateMinutes === 0?updateMinutes+"0":updateMinutes))
            } else {
                let hour = time;
                setUpdateHour(hour);
                setValue('TSTIME',(hour<10 ? "0"+ hour :hour)+":"+(updateMinutes === 0?updateMinutes+"0":updateMinutes))
            }
           
        } 

        if(type === 'minute') {
            let minutes = time;
            setUpdateMinutes(minutes);
            
            setValue('TSTIME',(parseInt(updateHour) <10 ? "0"+ parseInt(updateHour) : updateHour)+":"+(minutes === 0 ?"0"+minutes:minutes));
        }
       
   }
   const changeDate = (day) => {
        let weekend = localStorage.getItem('weekEnd');
        let split_weekend = weekend.split('/');
        let endDate = new Date(split_weekend[2]+'-'+split_weekend[1]+'-'+split_weekend[0]);
        let dayChange= endDate.setDate(endDate.getDate() - day)

    
        const timeZoneOffset = 10 * 60 * 60 * 1000; // AEST is UTC+10
        // Adjust the current date to AEST
         const dayAEST = new Date(dayChange + timeZoneOffset);
         // Extract the date part in "YYYY-MM-DD" format
         const newDay = dayAEST.toISOString().substring(0, 10);

        setValue('TSDATE', newDay )
   }

   const handleClearForm = () => {
        props.cancelEditMode();
        reset(); // reset the form to its initial state
        setValue('TSTIMES_AFTERHOURS', JSON.parse(localStorage.getItem('session')).LoginData.AfterHoursFlag)
        setUpdateHour(0);
        setUpdateMinutes(0);
    };

   

    useEffect(() => {
        
    
        setAfterHoursFlag(JSON.parse(localStorage.getItem('session')).LoginData.AfterHoursFlag);
        
   
        if (props.edit) {
            reset();
            let time = props.edit.formData.JobTime;
            let split_time = time.split(':');
            setUpdateHour(split_time[0]);
            setUpdateMinutes(split_time[1]);
           
            switch(props.edit.formData.JobComp) {
                case 'Xceed':
                    props.edit.formData.JobComp = props.edit.formData.JobComp.toUpperCase()
                    break;
                case 'Uniware':
                case 'UNI':
                    props.edit.formData.JobComp = "UNI"
                    break;
                case 'OzIsp':
                    props.edit.formData.JobComp = props.edit.formData.JobComp.toUpperCase()
                    break;
                    case 'UMS':
                    props.edit.formData.JobComp = props.edit.formData.JobComp.toUpperCase()
                    break;
                default: 
                    break;
            }
           
            // changeCompany(props.edit.formData.JobComp)
          
            // May need a more robust way of doing this.
            setValue('TSDESC', props.edit.formData.JobInfo)

            setValue('TSCODE', props.edit.formData.JobCode)
            setValue('TSCOMP', props.edit.formData.JobComp)
            
       
            setValue('TSCONT', (props.edit.formData.JobCont==="NOPRINT"? '':props.edit.formData.JobCont))
            setValue('TSCUST', props.edit.formData.JobCustomerCode)
            setValue('TSDESC', decodeURIComponent(props.edit.formData.JobInfo))
            setValue('TSQTCODE', (props.edit.formData.JobQuoteNum==="NOPRINT"?'':props.edit.formData.JobQuoteNum))
            setValue('TSJOBCODE', (props.edit.formData.JobNum==="NOPRINT"?'':props.edit.formData.JobNum))
            setValue('TSTIME', props.edit.formData.JobTime)
            setValue('TSMORE0', (decodeURIComponent(props.edit.formData.JobMore)==="NOPRINT"?'':decodeURIComponent(props.edit.formData.JobMore)))
            let formattedDateString = new Date("20" + props.edit.formData.JobYear + "-" + props.edit.formData.JobMonth + "-" +  props.edit.formData.JobDay.padStart(2, '0'))
                .toISOString()
                .substring(0, 10);

            setValue('TSDATE', formattedDateString)
            setValue('TSTIMES_AFTERHOURS', JSON.parse(localStorage.getItem('session')).LoginData.AfterHoursFlag)
         
        }

        if(props.isEditMode.editMode === false) {
            handleClearForm();
        }

    }, [props.edit,props.isEditMode.editMode, setValue])

    const onSubmit = data => {
        // do something with the form data, like submit it to a server
        
        const [day,
            month,
            year] = data
            .TSDATE
            .split('-');
        const dateObject = new Date(day, month - 1, year);
        const formattedDateString = dateObject.toLocaleDateString('en-AU', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });

        data.TSDATE = formattedDateString;
        // data.TSDESC = encodeURIComponent(data.TSDESC);
        data.TSDESC = encodeURIComponent(data.TSDESC);
        // data.TSMORE0 = encodeURIComponent(data.TSMORE0);
        data.TSMORE0 = encodeURIComponent(data.TSMORE0);
      
        if (props.edit) {

            let update = props.submission(data, props.edit.mode);
            
            props.cancelEditMode();
            update.then((result)=> 
            {
                if(result === 'Y'){
                    handleClearForm(); 
                } 
            });
           
        } else {
            let add = props.submission(data, 'ADD');
            add.then((result)=> 
            {
                if(result === 'Y'){
                    handleClearForm(); 
                } 
            });
        }

    };

    const changeCompany = (e) => {
       
        setChosenCompany(e);
        

      
    }



  
   

    const input_classname = 'p-1 border-2 border-uni-green-light w-full text-uni-green-dark';
    const button_classname= 'p-1 text-white bg-uni-green-dark border-4 text-sm border-uni-green-light flex-shrink hover:bg-uni-green-mid transition ease-in-out flex-1';
    const day_button= 'p-1 text-xs text-white bg-uni-green-dark border-4 text-sm border-uni-green-light flex-shrink hover:bg-uni-green-mid transition ease-in-out flex-1';

    return (
        <div className="px-3 ">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="my-1">
                    <input className={input_classname} type="date" {...register('TSDATE', { required: 'Date must be within the Working Week.'}) }/>
                    <div className='flex w-100'>
                        <button type='button' className={day_button} onClick={()=> changeDate(6)}>Sa</button>
                        <button type='button' className={day_button} onClick={()=> changeDate(5)}>Su</button>
                      
                        <button type='button' className={day_button} onClick={()=> changeDate(4)}>M</button>
                        <button type='button' className={day_button} onClick={()=> changeDate(3)}>T</button>
                        <button type='button' className={day_button} onClick={()=> changeDate(2)}>W</button>
                        <button type='button' className={day_button} onClick={()=> changeDate(1)}>Th</button>
                        <button type='button' className={day_button} onClick={()=> changeDate(0)}>F</button>
                       
                    
                        </div>
                   {errors.TSDATE?.message && <p className="p-1 bg-red-700 text-white" >{errors.TSDATE?.message}</p> }
                
                </div>
                <div className="my-1">
                    <select className={input_classname} onChange={onChange} {...register('TSCOMP' , {required: "Select a company.", onChange: (e) => changeCompany(e.target.value)})}>
                        <option value="">Select Company</option>
                        {props.options.APITSCOMPANY
                            ? props
                                .options
                                .APITSCOMPANY
                                .TSTimesCompany
                                .map((value, index) => {
                                    return <option key={index} value={value.CompCode}>{value.CompDesc}</option>
                                })
                            : null
}
                    </select>
                    {errors.TSCOMP?.message && <p className="p-1 bg-red-700 text-white" >{errors.TSCOMP?.message}</p> }
                </div>
                <div className="my-1">
                    <select className={input_classname} {...register('TSCUST', { required: 'Select a Customer.'})}>
                        {chosenCompany ? <option value="">Select {chosenCompany} Customer</option>:''}
                        { props.options.APITSCOMPLIST
                            ? props
                                .options
                                .APITSCOMPLIST
                                .TSTimesCompList.filter((value)=> value.CompType === comp )
                                .map((value, index) => {
                                    return <option key={index} value={value.CompCode} data-comp={value.CompType}>{value.CompName} - {value.CompCode}</option>
                                })
                            : null
}
                    </select>
                    {errors.TSCUST?.message && <p className="p-1 bg-red-700 text-white" >{errors.TSCUST?.message}</p> }

                </div>
                <div className="my-1">
                    <select className={input_classname} {...register('TSCODE', { required: 'Select a Charge Method.'})}>
                        <option value="">Select Charge Method</option>
                        {props.options.APITSCODES
                            ?props
                                .options
                                .APITSCODES
                                .TSTimesBillCodes
                                .map((value, index) => {
                                  
                                    return (value.BillCode
                                        ? <option className='flex' key={index} value={value.BillCode}>
                                            {value.BillCode} - {value.BillCodedesc}
                                            </option>
                                        : <option className='flex' key={index} value={value.srchcode}>
                                            {value.srchcode} - {value.srchdesc}
                                           
                                            </option>)
                                })
                            : null
}
                    </select>
                    {errors.TSCODE?.message && <p className="p-1 bg-red-700 text-white" >{errors.TSCODE?.message}</p> }
                </div>
                <div className="my-1">
                    <input
                        className={input_classname}
                        type="text"
                        placeholder='Quote Number'
                        {...register('TSQTCODE')}/></div>
                <div className="my-1">
                    <input
                        className={input_classname}
                        type="text"
                        placeholder='Job Number'
                        {...register('TSJOBCODE')}/></div>
                {/* <div className="my-1">
                    <input
                        className={input_classname}
                        type="text"
                        placeholder='Team Member'
                        {...register('teamMember')}/></div> */}
                <div className="my-1">
                    <textarea
                        className={input_classname}
                        type="text"
                        placeholder='Invoice Description' rows="3"
                        {...register('TSDESC', { required: 'Please Leave a short description of the work completed.'})}></textarea>
                     {errors.TSDESC?.message && <p className="p-1 bg-red-700 text-white" >{errors.TSDESC?.message}</p> }
                </div>
                <div className="my-1">
                    <textarea
                        className={input_classname} rows="3"
                        {...register('TSMORE0')}
                        placeholder='More Information' ></textarea></div>
                <div className="my-1">
                    <input
                        className={input_classname}
                        type="text"
                        placeholder='Contact Name'
                        {...register('TSCONT')}/></div>
                <div className="my-1">
                <div className='flex w-100'>
                        <button type='button' className={button_classname} onClick={()=> changeTime(0,'hour')}>0</button>
                        <button type='button' className={button_classname} onClick={()=> changeTime(1,'hour')}>1</button>
                        <button type='button' className={button_classname} onClick={()=> changeTime(2,'hour')}>2</button>
                        <button type='button' className={button_classname} onClick={()=> changeTime(3,'hour')}>3</button>
                    
                        <button type='button' className={button_classname} onClick={()=> changeTime('add','hour')}>+</button>
                        </div>
                    <input
                        className={input_classname}
                        type="text"
                        pattern="^(0?[1-9]?|1[0-2]?|00?):?(00|15|30|45)?$"
                        title="Must be greater than 00:15 and in 15 minute increments."
                        placeholder='00:00'
                        {...register('TSTIME',{ required: 'Must be greater than 00:15 and in 15 minute increments.', pattern:"^(0?[1-9]|1[0-2]|00):?(00|15|30|45)?$"})}/>
                        
                        <div className='flex w-100'>
                            <button type='button' className={button_classname} onClick={()=> changeTime(0,'minute')}>00</button>
                            <button type='button' className={button_classname} onClick={()=> changeTime(15,'minute')}>15</button>
                            <button type='button' className={button_classname} onClick={()=> changeTime(30,'minute')}>30</button>
                            <button type='button' className={button_classname} onClick={()=> changeTime(45,'minute')}>45</button>
                            </div>
                         {errors.TSTIME?.message && <p className="p-1 bg-red-700 text-white" >{errors.TSTIME?.message}</p> }
                        
                        </div>
                        <div className="my-1">
                    <label className='flex items-center space-x-2' >
                    
                    <input className="accent-uni-green-dark"
                        type="checkbox" 
                        onClick={updateAfterHoursFlag}
                      
                        {...register('TSTIMES_AFTERHOURS')}/>
                         {errors.TSTIMES_AFTERHOURS?.message && <p className="p-1 bg-red-700 text-white" >{errors.TSTIMES_AFTERHOURS?.message}</p> }
                        <div className="text-uni-green-light ">After Hours?</div> 
                    </label>
                   
                        </div>
                <div className='flex py-2 justify-between'>
                     <button
                            className='p-1 text-white bg-uni-green-dark border-4 text-sm border-uni-green-light flex-shrink hover:bg-uni-green-mid transition ease-in-out'
                            type="button"
                            onClick={() => handleClearForm()}>{props.edit
                                ? "Cancel"
                                : "Clear"}</button>
                    {props.loader?<LoaderCircle />
                             : <button
                             className='p-1 text-white bg-uni-green-dark border-4 text-sm border-uni-green-light flex-grow hover:bg-uni-green-mid transition ease-in-out'
                             type="submit">{props.edit
                                 ? "Update Job"
                                 : "Add Job"} 
                                 </button> }
                       
                            
                          

                </div>

            </form>
        </div>
    );
};
export default JobForm;